import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Layout from '../../../../components/Layout';
import HeadingDecorated from '../../../../components/HeadingDecorated';
import TeamMember from '../../../../components/TeamMember';
import Seo from '../../../../components/Seo';
import arrowLeftImg from '../../../../images/arrow-left.png';
import { LOCALIZED_PAGES } from '../../../o-nas/zespol/wspolpracownicy';
import '../../../../css/about.scss';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      fellows: allStrapiTeamMember(
        sort: { fields: position, order: ASC }
        filter: {
          localizations: {
            data: { elemMatch: { attributes: { locale: { eq: "en" } } } }
          }
          section: { eq: "fellows" }
        }
      ) {
        nodes {
          localizations {
            data {
              attributes {
                bio
                fullName
                jobTitle
              }
            }
          }
          avatar {
            localFile {
              url
            }
          }
          email
        }
      }
      stazysci: allStrapiTeamMember(
        sort: { fields: position, order: ASC }
        filter: {
          localizations: {
            data: { elemMatch: { attributes: { locale: { eq: "en" } } } }
          }
          section: { eq: "stazysci" }
        }
      ) {
        nodes {
          localizations {
            data {
              attributes {
                bio
                fullName
                jobTitle
              }
            }
          }
          avatar {
            localFile {
              url
            }
          }
          email
        }
      }
    }
  `);

  return (
    <Layout locale="en" localizedPages={LOCALIZED_PAGES}>
      <Seo title="About us | Fellows and Interns" />
      <div className="about">
        <section>
          <HeadingDecorated className="about__section-heading">
            Fellows
          </HeadingDecorated>
          <div className="about__section-content">
            {data.fellows.nodes.map((item) => (
              <TeamMember
                data={item}
                key={item.localizations.data[0].attributes.fullName}
              />
            ))}
          </div>
        </section>
        <section>
          <HeadingDecorated className="about__section-heading">
            Interns
          </HeadingDecorated>
          <div className="about__section-content">
            {data.stazysci.nodes.map((item) => (
              <TeamMember
                data={item}
                key={item.localizations.data[0].attributes.fullName}
              />
            ))}
          </div>
        </section>
        <Link to="/en/about-us/team/" className="about__footer-link">
          <img src={arrowLeftImg} alt="&larr;" />
          Team
        </Link>
      </div>
    </Layout>
  );
};

export default AboutPage;
